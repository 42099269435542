.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.triggerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hint {
  display: none;
  position: absolute;
  height: 0;
  transition: all 500ms;
  opacity: 0;
  z-index: -1;

  &Visible {
    z-index: 3;
    display: block;
    opacity: 1;
    height: fit-content;
  }

  &.bottomRight,
  &.bottomLeft,
  &.topLeft,
  &.topRight,
  &.leftBottom,
  &.leftTop,
  &.rightBottom,
  &.rightTop {
    .hintTriangle {
      width: 60px;
      height: 20px;
      position: absolute;
      overflow: hidden;
      z-index: 4;

      &:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: var(--white-color);
        transform: rotate(45deg);
        top: 10px;
        left: 7px;
        box-shadow: 0px 0px 10px 0px #00000040;
      }

      &Gray {
        &:after {
          background: #edf2f6;
          box-shadow: none;
        }
      }
    }
  }

  &.bottomRight {
    margin-top: 20px;
    top: 100%;
    left: -40px;

    .hintTriangle {
      left: 30px;
      top: -20px;
    }
  }

  &.bottomLeft {
    margin-top: 20px;
    top: 100%;
    right: -23px;

    .hintTriangle {
      top: -20px;
      right: 27px;
    }
  }

  &.topLeft {
    margin-bottom: 20px;
    bottom: 100%;
    right: -23px;

    .hintTriangle {
      bottom: -20px;
      right: 44px;
      rotate: 180deg;
    }
  }

  &.topRight {
    margin-bottom: 20px;
    bottom: 100%;
    left: -33px;

    .hintTriangle {
      bottom: -20px;
      left: 20px;
      rotate: 180deg;
    }
  }

  &.leftBottom {
    margin-right: 20px;
    top: -34px;
    right: calc(100% + 20px);

    .hintTriangle {
      margin-left: 91%;
      top: 45px;
      rotate: 90deg;
    }
  }

  &.leftTop {
    margin-right: 20px;
    bottom: -10px;
    right: calc(100% + 20px);

    .hintTriangle {
      margin-left: 91%;
      bottom: 5px;
      rotate: 90deg;
    }
  }

  &.rightBottom {
    margin-left: 20px;
    top: -50px;
    left: calc(100% + 20px);

    .hintTriangle {
      left: -40px;
      top: 45px;
      rotate: -90deg;
    }
  }

  &.rightTop {
    margin-left: 20px;
    bottom: -10px;
    left: calc(100% + 20px);

    .hintTriangle {
      left: -40px;
      bottom: 25px;
      rotate: -90deg;
    }
  }

  &ContentContainer {
    padding: 16px 20px;
    width: max-content;
    min-width: 100px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #00000040;
    background-color: white;
    position: relative;
    z-index: 3;

    &Gray {
      background-color: #edf2f6;
      box-shadow: none;
    }
  }

  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    transition: all 500ms;
    width: 8px;

    &:hover {
      opacity: 0.8;
    }
  }
}
