@import "/src/styles/mixin";

.modal_container {
  padding: 0 0;
}

.container {
  width: 100%;
  max-width: 400px;
  padding: 40px;

  //@media screen and (min-width: 320px) and (max-width: px) {
  //
  //}

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 320px;

    &Info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    .text {
      @include font16;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }

    .img {
      width: 169px;
    }

    .couponContainer {
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: 1px solid #c9ced6;
      border-radius: 5px;
      width: 100%;
    }

    .coupon {
      @include font24;
      font-weight: 600;
      line-height: 28px;
    }
  }
}
