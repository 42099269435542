@import "/src/styles/mixin";

.modalContainer {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
}

.wrap {
  width: calc(100vw - 24px);
  max-width: 310px;
  @media only screen and (min-width: 320px) and (max-width: 425px) {
    max-width: calc(100vw - 40px);
  }
}
.text {
  @include font14;
  margin-bottom: 12px;
}
